import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ButtonModel from '../components/button/buttonModel';
import TotalWorker from '../components/home/totalWorker';
import AlertTable from '../components/home/alertTable';
import WorkerInZone from '../components/home/workerInZone';
import { DashbardDetail, SiteMapDetail, Zone, GroupedPresentWorkers, ZonerStatus } from '../model/home/homePageModel';
import ZoneList from '../components/home/zoneList';
import WorkInZoneDetail from '../components/home/workInZoneDetail';
import ZoneDetail from '../components/home/zoneDetail';
import AlertTableDetail from '../components/home/alertTableDetail';
import FilterDetail from '../components/home/filterDetail';
import { useSelector } from "../redux/store";
import { useDispatch } from "react-redux";
import { showFilter, showFilterHideOption, showLoading } from '../redux/viewSlice';
import { AppService } from '../services/appService';
import { useNavigate } from 'react-router-dom';
import { ControlZone, ProjectInfo } from '../model/project/projectModel';
import { HandleLogout, RefreshToken } from '../services/authenticationService';
import { ProjectFilter } from '../redux/filterSlice';
import axios from 'axios';
import ZoomPanSitemap from '../components/home/zoom-pan-sitemap';
import { MessageDialog } from '../components/dialog';
import { getZoneAsOfTime } from '../extension/dateExtension';
import { AlertTableModel } from '../model/alertTable/alertTableModel';
import '../i18n'
import { useTranslation } from "react-i18next";
import { FILTER_HEALTH, FILTER_HUMAN, FILTER_MANCHINE } from '../model/constants';
// import { SampleAlertTableList } from '../testing/sampleData';

enum ContentType {
	normal,
	siteMap,
	zoneDetail,
	alertTable,
	workerInZoneDetail,
	workerInZoneDetailSec,
}

const Home: React.FC<{ appService: AppService }> = ({ appService }) => {

	const { t } = useTranslation()

	const [isAlertWarning, setIsAlertWarning] = useState(false);
	const [isFiltierAlertWarning, setIsFiltierAlertWarning] = useState(false);
	const [open, setOpen] = useState(false);

	const [dashboardDetail, setDashboardDetail] = useState<DashbardDetail>();
	const [normalZoneList, setNormalZoneList] = useState<Zone[]>();
	const [controlZoneDetail, setControlZoneDetail] = useState<ControlZone[]>();
	const [alertTableList, setAlertTableList] = useState<AlertTableModel>();

	const [contentType, setContentType] = useState<ContentType>();
	const [selectedZone, setSelectedZone] = useState<Zone | ControlZone>();
	const [zonerStatusList, setZonerStatusList] = useState<ZonerStatus[]>();
	const [validAdminPanel, setValidAdminPanel] = useState<boolean>(false);

	const filterModel = (useSelector((state: any) => state.filter.projectFilter)) as ProjectFilter;

	const isShowFilter = useSelector((state: any) => state.view.showFilter);
	const isShowFilterHideOption = useSelector((state: any) => state.view.showFilterHideOption);
	const project = useSelector((state: any) => state.user.project as ProjectInfo);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const controller = new AbortController();

	let CancelToken = axios.CancelToken;
	let source = CancelToken.source();

	const [isFiltering, setIsFiltering] = useState<boolean>(false);
	const [siteMapDetail, setSiteMapDetail] = useState<SiteMapDetail>();

	var isRefreshingToken = false

	const [staticAsOf, setStaticAsOf] = useState<string>();
	const [normalZoneAsOf, setNormalZoneAsOf] = useState<string>();
	const [controlZoneAsOf, setControlZoneAsOf] = useState<string>();

	useEffect(() => {
		if (project === null || project.projectID === null) {
			HandleLogout(dispatch, navigate)
			return
		}
		if (isFiltering === false) {
			setContentType(ContentType.siteMap)
			setSelectedZone(undefined)
		}

		getZoneList()
		getControlZoneDetail();
		getControlZoneAlertStatus(); // get different filter control zone info
		getDashboardData(true);
		getAlertTable();
		getSiteMapInfo();
		getZonerStatus();
		getAdminPanelValid();

		const timer = setInterval(() => {
			getZoneList()
			getZonerStatus();
		}, 1000 * 15);

		const statisticsTimer = setInterval(() => {
			getDashboardData(false);
		}, 1000 * 60);

		const controlZoneTimer = setInterval(() => {
			getControlZoneDetail();
			getControlZoneAlertStatus();
		}, 1000 * 5);

		return () => {
			source.cancel('Cancelled request');
			clearInterval(timer);
			clearInterval(statisticsTimer);
			clearInterval(controlZoneTimer);
		};

	}, [project, filterModel]);

	const getSiteMapInfo = async () => {
		const url = process.env.REACT_APP_CMS_API_URL + '/project/:projectId/sitemap-annotations';
		await axios.get(
			url.replace(':projectId', project.projectID),
			{
				withCredentials: false
			}
		).then((res) => {
			setSiteMapDetail(res.data.data);
		}).catch((err) => {
			// console.log(err);
		})
	}

	const getAdminPanelValid = async () => {
		const url = process.env.REACT_APP_ADMIN_PANEL_URL_API + '/admin-users/validate';
		await axios.post(
			url,
			{
				"projectID": project.projectID,
				"username": localStorage.getItem('uid'),
			}
		).then((res) => {
			setValidAdminPanel(res.data.data.valid)
		}).catch((err) => {
			// console.log(err);
		})
	}

	const getDashboardData = (_showLoading: boolean = false) => {
		controller.abort();
		dispatch(showLoading(_showLoading));
		appService.dashboardService
			.fetchData({
				"projectID": project.projectID,
				"projectUID": project.id,
				"contractorID": filterModel.constructorUid !== "all" ? filterModel.constructorUid : null,
				"contractor": filterModel.constructorCode !== "all" ? filterModel.constructorCode : null,
				"trade": filterModel.type !== "所有" ? filterModel.type : null,
			}, source.token)
			.subscribe(
				(value: any) => {
					if (_showLoading === true) {
						dispatch(showLoading(false));
					}
					if (isFiltering) {
						setIsFiltering(false);
					}
					setDashboardDetail(value.data.data);
					setStaticAsOf(getZoneAsOfTime(new Date()))
					if (isRefreshingToken) {
						isRefreshingToken = false
					}
				},
				(error) => {
					if (!isRefreshingToken) {
						isRefreshingToken = true
						refreshTokenHandle(error.httpStatusCode)
					}
				}
			);
	}

	const getZoneList = () => {
		appService.dashboardService
			.getZoneDetail({
				"projectID": project.projectID,
				"projectUID": project.id,
				"contractorID": filterModel.constructorUid !== "all" ? filterModel.constructorUid : null,
				"contractor": filterModel.constructorCode !== "all" ? filterModel.constructorCode : null,
				"zones": null,
				"trade": filterModel.type !== "所有" ? filterModel.type : null,
				"workerType": (filterModel.filterType === FILTER_HUMAN
					|| filterModel.filterType === FILTER_HEALTH) ? "human" : "machine"
			})
			.subscribe(
				(value: any) => {
					setNormalZoneList(value.data.data.zones)
					setNormalZoneAsOf(getZoneAsOfTime(new Date()))
				},
				(error) => {
					RefreshToken(
						appService,
						error.httpStatusCode,
						dispatch,
						navigate,
						() => {
							dispatch(showLoading(false));
							getZoneList()
						}
					);
				}
			)
	}

	const getControlZoneDetail = () => {
		controller.abort();
		appService.dashboardService
			.getControlZone({
				"projectID": project.projectID,
				"projectUID": project.id,
				"workerType": (filterModel.filterType === FILTER_HUMAN
					|| filterModel.filterType === FILTER_HEALTH) ? "human" : "machine"
			})
			.subscribe(
				(value: any) => {
					setControlZoneDetail(value.data.data)
					setControlZoneAsOf(getZoneAsOfTime(new Date()))
					if ((value.data.data?.filter((e: any) => e.unauthorizedWorkers.count > 0) ?? []).length > 0) {
						setIsAlertWarning(true)
						getAlertTable()
					} else {
						setIsAlertWarning(false)
					}
					if (isRefreshingToken) {
						isRefreshingToken = false
					}
				},
				(error) => {
					if (!isRefreshingToken) {
						isRefreshingToken = true
						refreshTokenHandle(error.httpStatusCode)
					}
				}
			)
	}

	const getControlZoneAlertStatus = () => {
		controller.abort();
		appService.dashboardService
			.getControlZone({
				"projectID": project.projectID,
				"projectUID": project.id,
				"workerType": (filterModel.filterType === FILTER_HUMAN
					|| filterModel.filterType === FILTER_HEALTH) ? "machine" : "human"
			})
			.subscribe(
				(value: any) => {
					if ((value.data.data?.filter((e: any) => e.unauthorizedWorkers.count > 0) ?? []).length > 0) {
						setIsFiltierAlertWarning(true)
						getAlertTable()
					} else {
						setIsFiltierAlertWarning(false)
					}
					if (isRefreshingToken) {
						isRefreshingToken = false
					}
				},
				(error) => {
					if (!isRefreshingToken) {
						isRefreshingToken = true
						refreshTokenHandle(error.httpStatusCode)
					}
				}
			)
	}

	const getAlertTable = () => {
		appService.dashboardService
			.getAlertTable({
				"projectID": project.projectID,
				"page": 0,
				"pageSize": 20
			})
			.subscribe(
				(value: any) => {
					console.log(value)
					setAlertTableList(value.data.data);
					if (isRefreshingToken) {
						isRefreshingToken = false
					}
				},
				(error) => {
					if (!isRefreshingToken) {
						isRefreshingToken = true
						refreshTokenHandle(error.httpStatusCode)
					}
				}
			);
	}

	const getZonerStatus = () => {
		appService.zonerStatusServices
			.getZonerStatus(project.projectID)
			.subscribe(
				(value: any) => {
					setZonerStatusList(value.data.data);
					if (isRefreshingToken) {
						isRefreshingToken = false
					}
				},
				(error) => {
					if (!isRefreshingToken) {
						isRefreshingToken = true
						refreshTokenHandle(error.httpStatusCode)
					}
				}
			)
	}

	const refreshTokenHandle = (errorCode: number) => {
		RefreshToken(
			appService,
			errorCode,
			dispatch,
			navigate,
			() => {
				getZoneList()
				getControlZoneDetail()
				getAlertTable();
				getDashboardData(true);
			},
			() => {
				isRefreshingToken = false
				setOpen(true);
				dispatch(showLoading(false));
			});
	}

	const handleClose = () => {
		setOpen(false);
	};

	const resetShowFilter = () => {
		dispatch(showFilterHideOption(false));
		dispatch(showFilter(false));
	}

	const workerInZoneFirst: ButtonModel = {
		title: t('home_worker_present_show_all'),
		onClick: () => {
			resetShowFilter()
			setContentType(ContentType.workerInZoneDetail)
		},
		style: {
			height: '40px',
		}
	}

	const bottomZoneGroup = (zoneList: Zone[]) => (
		<div className='home-bottom-zone-div'>
			<ZoneList
				title={t('home_working_zone')}
				selectedZone={selectedZone}
				zoneList={zoneList}
				lastUpdateTime={normalZoneAsOf ?? ""}
				isControlZone={false}
				onItemClickHandle={(value: any) => {
					resetShowFilter();
					setSelectedZone(value);
					setContentType(ContentType.zoneDetail);
				}}
				mutedList={[]}
			/>
		</div>
	)
	const bottomRestrictZoneGroup = (zoneList: ControlZone[]) => (
		<div className='home-bottom-zone-div'>
			<ZoneList
				title={t(filterModel.filterType === FILTER_MANCHINE ?
					'home_controlled_zone_mechine_title' :
					'home_controlled_zone')
				}
				selectedZone={undefined}
				controlZoneList={zoneList}
				lastUpdateTime={controlZoneAsOf ?? ""}
				isControlZone={true}
				onItemClickHandle={(value) => {
					resetShowFilter()
					setSelectedZone(value)
					setContentType(ContentType.zoneDetail)
				}}
				showAdminBtn={validAdminPanel}
				mutedList={(zonerStatusList ?? []).filter(e => (e.status?.includes("OFF") ?? false)).map(e => e.zone)}
			/>
		</div>
	)

	const mainContent = (innerContent?: JSX.Element) => {
		const zoneList = (normalZoneList ?? []).filter((ele) => {
			return ele.count > 0
		});
		const _controlZoneDetail = controlZoneDetail ?? [];
		return (
			<div className='home-bottom-section-div'>
				<div className='home-bottom-section-zone-div'>
					<div className='home-bottom-section-zone-inner-div'>
						{bottomZoneGroup(zoneList)}
						{bottomRestrictZoneGroup(_controlZoneDetail)}
					</div>
				</div>
				{innerContent}
			</div>
		);
	}

	const _setSelectZone = (zoneName: string, zoneList: (ControlZone | Zone)[]) => {
		console.log(zoneList)
		const result = zoneList.filter(e => {
			const zone = (e as ControlZone);
			if (zone != null && zone.zone === zoneName) {
				return e
			} else {
				const normalZone = e as Zone
				if (normalZone.name === zoneName) {
					return e
				}
			}
		})
		if (result.length > 0) {
			setSelectedZone(result[0]);
		}
	}

	const siteMap = (): JSX.Element => {
		const normalZone = normalZoneList ?? [];
		const controlZone = controlZoneDetail ?? [];
		const _zoneList = [...normalZone, ...controlZone];
		const imgUrl = siteMapDetail?.sitemapImage != null ? siteMapDetail?.sitemapImage.url : '';
		return (
			<div className='home-bottom-site-map-div'>
				<ZoomPanSitemap
					zones={normalZone}
					controlZone={controlZone}
					imageUrl={imgUrl}
					annotations={siteMapDetail?.sitemapAnnotations ?? []}
					onClickImage={(coordinate) => {
						// console.log(coordinate)
					}}
					onClickAnnotationOfIndex={(index, zoneName) => {
						console.log(`check ${zoneName}`)
						_setSelectZone(zoneName, _zoneList)
						setContentType(ContentType.zoneDetail)
					}}
				/>
			</div>
		);
	}

	const filterContent = (hideOption: boolean): JSX.Element => {
		return (
			<div className='full-size-div' style={{ flex: 8 }}>
				<FilterDetail
					appService={appService}
					projectId={project.projectID}
					hideOption={hideOption}
					confirmCallBack={() => {
						resetShowFilter()
						setIsFiltering(true);
						if ((selectedZone === null || selectedZone === undefined)
							&& (dashboardDetail?.zones?.length ?? 0) > 0) {
							setSelectedZone(dashboardDetail?.zones[0])
						}
						setContentType(ContentType.siteMap);
					}}
					closeCallBack={() => {
						resetShowFilter()
						setContentType(ContentType.siteMap)
					}} />
			</div>
		);
	}

	const workInZone = (): JSX.Element => {
		const list: GroupedPresentWorkers[] = [];
		if (dashboardDetail?.presentWorkers[0] != null) {
			dashboardDetail?.presentWorkers[0].contractors.forEach(e => {
				list.push({
					contractor: e.contractor,
					morningContractors: e,
				})
			})
		}

		if (dashboardDetail?.presentWorkers[1] != null) {
			dashboardDetail?.presentWorkers[1].contractors.forEach(e => {
				const index = list.findIndex(v => v.contractor === e.contractor)
				if (index !== -1) {
					list[index].afternoonContractors = e
				} else {
					list.push({
						contractor: e.contractor,
						afternoonContractors: e,
					})
				}
			})
		}

		return (
			<div className='full-size-div' style={{ flex: 8 }}>
				<WorkInZoneDetail
					groupedList={list}
					closeCallBack={() => {
						setContentType(ContentType.siteMap)
					}}
				/>
			</div>
		);
	}

	const alertDetail = (): JSX.Element => {
		return (
			<div className='full-size-div' style={{ flex: 8 }}>
				<AlertTableDetail
					appService={appService}
					projectId={project.projectID}
					projectUid={project.id}
					isAlertWarning={isAlertWarning || isFiltierAlertWarning}
					canDeleteMsg={validAdminPanel}
					deleteAllMessageHandle={() => {
						getAlertTable();
					}}
					closeCallBack={() => {
						setContentType(ContentType.siteMap)
					}} />
			</div>
		);
	}

	const zoneDetail = (detail?: Zone | ControlZone, isControlZone: boolean = false): JSX.Element => {
		const matchStatus = ((zonerStatusList ?? []).filter(e => e.zone === (detail as ControlZone)?.zone))
		var isMuted = false
		if (matchStatus.length > 0) {
			isMuted = !(matchStatus[0].status === "OFF")
		}
		return (
			<div >
				<ZoneDetail
					appService={appService}
					projectId={project.projectID}
					projectUId={project.id}
					detail={detail}
					closeCallBack={() => {
						setContentType(ContentType.siteMap)
					}}
					isControlZone={isControlZone}
					isMuted={isMuted}
					muteToggleHandle={() => {
						getZonerStatus()
					}}
				/>
			</div >

		)
	}

	const renderContent = (): JSX.Element => {
		switch (contentType) {
			case ContentType.siteMap:
				return mainContent(siteMap());
			case ContentType.zoneDetail:
				var _isControlZone = false
				const _controlZone = (selectedZone as ControlZone)
				if (_controlZone != null) {
					_isControlZone = _controlZone?.authorizedWorkers != null && _controlZone?.unauthorizedWorkers != null
				}
				return zoneDetail(selectedZone, _isControlZone);
			case ContentType.alertTable:
				return mainContent(alertDetail());
		}
		return mainContent(siteMap());
	}

	const isShowNormalContent = (): boolean => {
		return (!isShowFilter
			&& contentType !== ContentType.workerInZoneDetail
			&& contentType !== ContentType.workerInZoneDetailSec)
	}

	return (
		<div className='main-container'>
			<Grid className='home-grid'>
				<div className='home-top-section-div'>
					<div className='home-top-total-woker-div'>
						<TotalWorker
							workCount={`${dashboardDetail?.totalWorkers ?? "-"}`}
							lastUpdateTime={staticAsOf ?? ""}
						/>
					</div>
					<div className='home-top-woker-in-zone-div'>
						<WorkerInZone
							time={t('home_09_worker_present')}
							count={dashboardDetail?.presentWorkers[0].count}
							btnModel={workerInZoneFirst}
							showButton={true}
						/>
						<div style={{ height: '10px' }} />
						<WorkerInZone
							time={t('home_02_worker_present')}
							count={dashboardDetail?.presentWorkers[1].count}
							showButton={false}
						/>
					</div>
					<div className='home-top-alert-table-div'>
						<AlertTable
							messageList={(alertTableList?.data ?? []).slice(0, 2)}
							isAlertWarning={isAlertWarning || isFiltierAlertWarning}
							showAllCallBack={() => {
								resetShowFilter()
								setContentType(ContentType.alertTable)
							}}
							mutedCount={zonerStatusList?.filter(e => (e.status ?? "").includes("OFF")).length ?? 0}
						/>
					</div>
				</div>
				{
					isShowFilter ? mainContent(filterContent(true)) :
						isShowFilterHideOption ? mainContent(filterContent(false)) :
							(contentType === ContentType.workerInZoneDetail) ? mainContent(workInZone()) :
								(isShowNormalContent()) ? renderContent() : null
				}

			</Grid >
			<MessageDialog
				dialogProps={{
					title: t('dialog_error_title'),
					content: t('dialog_error_message'),
					btnTitle: t('dialog_error_button'),
					open: open,
					handleClose: handleClose
				}}
			/>
		</div >
	);
}

export default Home;